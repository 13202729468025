import {conn} from './api';

const getPatterns = () => {
    return new Promise(async (resolve,reject) => {
        await conn.get('/patterns/').then((res) => {
            return resolve(res);
        }).catch((err) => {
            console.log('Error', err.data);
            return resolve(err.data);
        });
    });
}

const getCategories = () => {
    return new Promise(async (resolve,reject) => {
        await conn.get('/categories/').then((res) => {
            return resolve(res);
        }).catch((err) => {
            console.log('Error', err.data);
            return resolve(err.data);
        });
    });
}

const getPatternsByCategory = (catId) => {
    return new Promise(async (resolve,reject) => {
        await conn.get(`/patterns/${catId}/`).then((res) => {
            return resolve(res);
        }).catch((err) => {
            console.log('Error', err.data);
            return resolve(err.data);
        });
    });
}

const getRandomPattern = () => {
    return new Promise(async (resolve,reject) => {
        await conn.get(`/patterns/randompattern/`).then((res) => {
            return resolve(res);
        }).catch((err) => {
            console.log('Error', err.data);
            return resolve(err.data);
        });
    });
}

export {getPatterns, getCategories, getPatternsByCategory,getRandomPattern};