import { useState, useEffect } from "react";

const PatternGrid = (props) => {
    const itemsPerPage = 4;
    const pageNumbers =  Math.ceil(props.patterns.length / itemsPerPage);
    const [page,setPage] = useState(1);
    const [pageData,setPageData] = useState([]);

    useEffect(() =>{
        setPageData(getPage(props.patterns,page));
    },[]);

    const getPage = (data,pageNo) => {
        const startIndex = (pageNo - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const paginatedData = data.slice(startIndex, endIndex);
        return paginatedData;
    }
      
    const nextPage = () => {
        if(page < pageNumbers) {
            setPage(page+1);
            setPageData(getPage(props.patterns,page+1));
        }
    }

    const prevPage = () => {
        if(page > 1) {
            setPage(page-1);
            setPageData(getPage(props.patterns,page-1));
        }
    }

    return(
        <div className="pattern-container">
            <div className="pattern-grid">
                {   
                    pageData.length > 0
                    ? pageData.map((pattern) => {
                        return (
                            <div key={pattern.id} className="pattern-tile" onClick={() => props.setBg(pattern.picture)}>
                                <div className="pattern-title">
                                    <h3>{ pattern.name }</h3>
                                </div>
                                <div className="pattern-img">
                                    <img src={pattern.picture} alt={pattern.description} />
                                </div>
                                <div className="download-linl">
                                    <a href={pattern.picture} download target="_blank">Download</a>
                                </div>
                            </div>
                        );
                    })
                    : <p>Sorry there are no patterns for this selection.</p>
                }
            </div>
            {
                pageData.length > 0
                ?   <div className="page-selector">
                        <p><button className={page === 1 ? "btn btn-disabled" : "btn"} disabled={page === 1} onClick={prevPage} >Prev</button></p>
                        <p>Page { page } of { pageNumbers }</p>
                        <p><button className={page === pageNumbers ? "btn btn-disabled" : "btn"} disabled={page === pageNumbers} onClick={nextPage} >Next</button></p>
                    </div>
                :   null
            }
            <a href="https://www.redbubble.com/people/sejenator/shop?artistUserName=Sejenator&collections=3424405&iaCode=all-departments&sortOrder=relevant" target="_blank">
                Buy patterned merchandise online
            </a>
        </div>
    );
}

export default PatternGrid;