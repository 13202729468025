import {useEffect} from "react";

const CategorySelector = (props) => {
    useEffect(() => {

    },[]);

    const fetchByCategory = () => {
        const categorySelect =  document.querySelector('#category-select');
        props.loadAction(categorySelect.value);
    }

    return(
        <div className="category-selector">
            <label for="category-select">Filter by category:</label>
            <select id="category-select">
                {
                    props.categories.map((category) => {
                        return(
                            <option value={category.id}>{category.category}</option>
                        );
                    })
                }
            </select>
            <button onClick={fetchByCategory}>Go</button>
        </div>
    );
}

export default CategorySelector;