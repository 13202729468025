import './App.css';
import { useState, useEffect } from 'react';
import Home from './components/screens/Home';

function App() {
  const [hideContent, setHideContent] = useState(false);

  useEffect(() => {
    const header = document.querySelector('header');

    window.onscroll = function() {
      const navbar = document.querySelector(".nav-bar");
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
    
  },[]);


  return (
    <div className="App">
      <header>
          <h1>Pattern Play</h1>
          <button onClick={() => { hideContent ? setHideContent(false) : setHideContent(true) }}>
            { hideContent ? 'Show Content' : 'Hide Content' }
          </button>
      </header>
      <main>
        {
          hideContent ? <Home hidden={false} /> : <Home hidden={true} />
        }
        
      </main>
      <footer>
        &copy;Chris Briant {new Date().getFullYear()}
      </footer>
    </div>
  );
}

export default App;
