import { useEffect, useState } from 'react';
import {getPatterns, getCategories, getPatternsByCategory,getRandomPattern} from '../../network/apiactions';
import PatternGrid from '../widgets/PatternGrid';
import CategorySelector from '../widgets/CategorySelector';


const Home = (props) => {
    const [loaded,setLoaded] = useState(false);
    const [patterns,setPatterns] = useState([]);
    const [categories,setCategories] = useState([]);
    const [background, setBackground] = useState("url('https://patterns.eu-central-1.linodeobjects.com/patterns/1_Chris/Squids%20and%20Other%20Animals/da1543841b2aeb58f129e529ebf17494bc19d21f.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=P9UKPUEMU2QNJL1170HP%2F20230325%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230325T054536Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=2cc59498738b927dca6ad7362f62f6b3f4b7d4de8c6179e0036f999dc8dd28b8') center center / 200px 200px repeat");


    const loadPatterns = async () => {
      const patternData = await getPatterns();
      const categoryData = await getCategories();
      const randomPattern = await getRandomPattern();
      setBackground(`url('${randomPattern.data.picture}') center center / 200px 200px repeat`);
      setPatterns(patternData.data);
      setCategories(categoryData.data);
      setLoaded(true);
    }

    const loadPatternsByCategory = async (catId) => {
        setLoaded(false);
        const patternData = await getPatternsByCategory(catId);
        setPatterns(patternData.data);
        setLoaded(true);
    }

    const setBg = (url) => {
        setBackground(`url('${url}') center center / 200px 200px repeat`);
        //setBackgroundSize("200px 200px");
    }
  
    useEffect(() => {
      loadPatterns();
    },[]);

    return(
        <>
        { loaded 
            ?<div id="home" style={{ background }}>
                {
                    props.hidden
                    ? <>
                        <div className="homepage-title">
                            <h2>Select a Pattern</h2>
                        </div>
                        <CategorySelector categories={categories} loadAction={loadPatternsByCategory} />
                        <PatternGrid patterns={patterns} setBg={setBg} />
                    </>
                    : null
                }
            </div>
            : <p>Loading...</p> }
        </>
    );
}

export default Home;